export const DIALOG_EDIT = 'DIALOG_EDIT';
export const DIALOG_EDIT_FALSE = 'DIALOG_EDIT_FALSE';
export const DIALOG_HAPUS = 'DIALOG_HAPUS';
export const DIALOG_HAPUS_FALSE = 'DIALOG_HAPUS_FALSE';
export const DIALOG_ADD = 'DIALOG_ADD';
export const DIALOG_ADD_FALSE = 'DIALOG_ADD_FALSE';

export const LOADING_UI = 'LOADING_UI';
export const LOADING_DATA = 'LOADING_DATA';
export const STOP_LOADING_DATA = 'STOP_LOADING_DATA';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';

// USER

export const SET_USER = 'SET_USER';
export const AUTHENTICATION = 'AUTHENTICATION';
export const UNAUTHENTICATION = 'UNAUTHENTICATION';

export const GET_USER_EDIT = 'GET_USER_EDIT'



// SPG

export const SCAN_USER = 'SCAN_USER'
export const SCAN_USER_FALSE = 'SCAN_USER_FALSE'
export const SCAN_POPUP_FAILED = 'SCAN_POPUP_FAILED'
export const SCAN_POPUP_FAILED_FALSE = 'SCAN_POPUP_FAILED_FALSE'

export const SCAN_POPUP_GUEST = 'SCAN_POPUP_GUEST'
export const SCAN_POPUP_GUEST_FALSE = 'SCAN_POPUP_GUEST_FALSE'

export const SCAN_POPUP_PENGURUS = 'SCAN_POPUP_PENGURUS'
export const SCAN_POPUP_PENGURUS_FALSE = 'SCAN_POPUP_PENGURUS_FALSE'


export const SCAN_USER_NOTFOUND = 'SCAN_USER_NOTFOUND'
export const SCAN_USER_NOTFOUND_FALSE = 'SCAN_USER_NOTFOUND_FALSE'


// ERROR

export const SET_ERRORS = 'SET_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'


// DATA

export const GET_DATA = 'GET_DATA'
export const POST_DATA = 'POST_DATA'
export const DELETE_DATA = 'DELETE_DATA'
export const EDIT_DATA = 'EDIT_DATA'

export const GET_ALL_DATA = 'GET_ALL_DATA'

// NOTIF

export const ALERT_SUCCES = 'ALERT_SUCCES'
export const ALERT_SUCCES_FALSE = 'ALERT_SUCCES_FALSE'
export const ALERT_ERROR = 'ALERT_ERROR'
export const ALERT_ERROR_FALSE = 'ALERT_ERROR_FALSE'
export const ALERT_SUCCES_UPDATE = 'ALERT_SUCCES_UPDATE'
export const ALERT_SUCCES_UPDATE_FALSE = 'ALERT_SUCCES_UPDATE_FALSE'
export const ALERT_SUCCES_DELETE = 'ALERT_SUCCES_DELETE'
export const ALERT_SUCCES_DELETE_FALSE = 'ALERT_SUCCES_DELETE_FALSE'