import styled from 'styled-components';


export const DIV = styled.div`
    margin: auto;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;

@media only screen and (max-width: 800px) { 
    .grid-c {
        
    }
`;