import React, { useState, useEffect, useRef, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import axios from 'axios';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Linear from '../LoadingUI/Linear';
import { AlertSnackBarFailed } from '../Alert'
import { Context } from '../../context';
import { ALERT_SUCCES, LOADING_UI, STOP_LOADING_UI, SET_ERRORS, ALERT_ERROR, GET_DATA } from '../../utils/types'
import csv from "csvtojson";


const DialogImportFromExcel = ({style}) => {

  const { state, dispatch } = useContext(Context);
  const [open, setOpen] = useState(false);
  const [selectFile, setSelectFile] = useState(null);
  const [fileName, setFileName] = useState('Selected File');
  const [dataUserB, setDataUser] = useState(state.getData)
  const inputFileRef = useRef()


  const handleClickOpen = (e) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch({type: STOP_LOADING_UI })
    setSelectFile(null)
    setFileName('Selected File')
  };

  const onChangeHandler = (e) => {

      setSelectFile(e.target.files[0])

      setFileName(e.target.files[0].name)

   }

   const handleSubmit = async (e) => {

    dispatch({type: LOADING_UI })
    
    try {


        // convert csv to json
        function csvJSON(csv) {

            let lines = csv.split("\n");

            const header = lines.shift().split(";");

            return lines.map(line => {
                const bits = line.split(";")
                let obj = {};
                header.forEach((h, i) => obj[h] = bits[i]); // or use reduce here
                return obj;
            })
        }

        const reader = new FileReader();

        reader.readAsText(selectFile);

        let dataCsv = await new Promise(resolve => {
            reader.onload = () => {

                const text = reader.result;

                const csvToJson = csvJSON(text);

                return resolve(csvToJson);
            };
        });

        for (let i in dataCsv) {

            const values = Object.values(dataCsv[i]);

            const dataUser = {
                name: values[0],
                role: values[1],
                noHandphone: isNaN(Number(values[2])) ? 1 : values[2],
                noKursi: values[3],
                statusKehadiran: values[4],
                hasilSwab: values[5],
            }

            if (dataUser.name && dataUser.role && dataUser.noHandphone && dataUser.noKursi && dataUser.statusKehadiran && dataUser.hasilSwab) {

                // console.log("upload")
                // console.log(dataUser)
                await axios.post('https://webeo.wasdlabs.com/api/v1/users', dataUser)

            } else{

                // console.log("gagal")
                console.log(dataUser)
            }
        }

        setTimeout(function () {
            dispatch({type: STOP_LOADING_UI });
            dispatch({type: ALERT_SUCCES });
            setOpen(false);

        }, 1000);

        // const data = new FormData()
        //
        // data.append('csv', selectFile);

    //   const dataToExcel = await axios.post('https://webeo.wasdlabs.com/api/v1/users/importexl', data);
    //
    //    console.log(dataToExcel);
    //
    //   const x = dataToExcel.data.data;
    //
    //   const x1 = [...x, ...state.getData];
    //
    //   dispatch({ type: GET_DATA, value: x1})
    //

    } catch (err) {

      dispatch({type: SET_ERRORS, value: err.message});

      dispatch({type: ALERT_ERROR })
    }

  } 

  return (
    <div>
      { state.alertError ? (<AlertSnackBarFailed />) : null }
      <ImportExportIcon onClick={handleClickOpen} style={style} />
     <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'md'}
        BackdropProps={{style: {backdropFilter: 'blur(4px)', background: 'rgba(125, 144, 178, 0.5)'}}}
        PaperProps={{
          style: {
            boxShadow: 'none',
            borderRadius: '20px',
          },
        }}
      >
        {state.loading ? (<Linear />) : null }
        <DialogContent>
          <p>{fileName}</p>
        <input
          style={{display: 'none'}}
          id="contained-button-file"
          multiple={false}
          type="file"
          onChange={onChangeHandler}
        />
        <label htmlFor="contained-button-file">
          <Button 
                startIcon={<CloudUploadIcon />} 
                variant="contained" 
                color="primary" 
                component="span" 
                style={{left: '25%', width: '50%'}}
              >
            Select File
          </Button>
        </label>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Import Data
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default DialogImportFromExcel;